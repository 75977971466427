@use "../../utils/utils";
@use "../../utils/base";

.no-data-container {
  margin: auto;
  margin-top: 0rem;
  width: 100%;
  height: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.61);

  h2 {
    color: #333333;
    margin-top: 7.5rem;
    font-size: 1.8rem;
    word-spacing: 0.1rem;
    margin-bottom: 5rem;
  }

  p {
    color: #333333;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
}

.no-data-load-button {
  width: 25rem;
  padding: 0.5rem;
  border-radius: 0.3rem;

  transition-duration: 0.3s;

  font-size: 120%;
  color: utils.$chart-button-font;

  background: #454545;
}

.no-data-load-button:hover {
  background: utils.$btn-select-blue-hover;
}