.button {
  height: 36px;
  min-width: 36px;
  color: white;
  background-color: #454545;
  border-radius: 4px;
  gap: 0.5rem;
  transition: 0.2s;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #333333;
  }

  &:active {
    background-color: black;
  }

  &:disabled {
    background-color: lightgray;
    cursor: not-allowed;
  }
}