@use '../../utils/base';

.hr {
    margin-top: 0.5rem;
}

.container {
    box-shadow: 0px 2px 4px #82828240;
    border: 1px solid #E8E8E8;
}

.titleHeader {
    border-bottom: 1px solid #E8E8E8;
    padding: 1rem;
}

.childContainer {
    padding: 1rem;
}