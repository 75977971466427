.banner {
  padding: 10px 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  border-radius: 4px;
  border-style: solid;
  border-width: 1px;

  &[data-style="error"] {
    color: #721c24;
    background-color: #f8d7da;
    border-color:#f5c6cb;
  }

  &[data-style="warning"] {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &[data-small=true] {
    gap: 8px;
    padding: 4px 8px;
  }
}