.container {
  display: flex;
  align-items: center;
  gap: 1rem;
  
  button {
    height: 38px;
  }
}

.select-wrapper {
  position: relative;
}

.status {
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  right: 3rem;
  top: 0.5rem;
  height: calc(100% - 1rem);
  padding: 4px;
  border-radius: 6px;

  &[data-success=true] {
    background-color: #BCCF00;
  }

  &[data-success=false] {
    background-color: lightcoral;
  }

  animation: transient-bubble-animation 3s ease-in-out forwards;
}

@keyframes transient-bubble-animation {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pattern-row {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  align-items: start;

  // separate each element when there are multiple elements
  // (works only while one column in the grid)
  &:not(:last-child) {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 1rem;
  }
}