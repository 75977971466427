.tab {
  background-color: #f6f6f6;
  padding: 0.5rem;
  box-shadow: inset 0 0 0 1px #E3E3E3;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #333333; // todo: define globally
  cursor: pointer;

  &:hover {
    color: black;
  }

  &[data-selected=true] {
    background-color: white;
    box-shadow:
      inset 0 4px #BCCF00,
      inset 1px 0 #E3E3E3,
      inset -1px 0 #E3E3E3;
  }
}
