.container {
  margin-bottom: -1px; // overlap the top border of container to which the tabs are attached
  display: flex;
  gap: 0.5rem;
}

.tabs-container {
  display: flex;
  gap: 0.5rem;
  overflow-x: scroll;
  scrollbar-width: none;
}

.scroll-button {
  width: 2rem;
  background: none;
}