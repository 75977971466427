.list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-item-container {
  width: 100%;
  max-width: 40rem;
}

.add-button {
  display: flex;
  height: 3rem;
  width: 100%;
  max-width: 40rem;
  background: #f2f2f2;

  justify-content: center;
  align-items: center;

  img {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
}

.add-button:hover:enabled {
  background: #eeeeee;
}

.add-button:disabled {
  background: #f2f2f2;
}

.header {
  display: flex;
  width: 100%;
  padding-top: 0.5rem;
}

.timezone {
  margin-left: auto;
}

.row {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  min-height: 2rem;
}

.text{
  flex: 1;
  margin-bottom: 0;
}