.row {
  padding: 1rem;
  border-bottom: 1px solid #efefef;

  p {
    color: #666;
  }
}

.title {
  font-weight: bold;
}
