@use '../../../../utils/utils';

.card {
  margin: 0.5rem; 
  background-color: white;
  min-width: 20rem;

  box-shadow: 0px 2px 4px #82828240;

  @media only screen and (max-width: utils.$tablet-width) {
    min-width: calc(100% - 1rem);
  }
  border: 1px solid #E8E8E8;
}

.card-wide {
  min-width: calc(100% - 1rem);
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  align-items: flex-start;
  width: 100%;
}

.row {
  min-height: 4rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
}

.row:last-child {
  border-bottom: none;
}

.text{
  margin-left: 1rem;
  flex: 1;
  color: #666;
}

.icon {
  margin-left: 1rem;
}

.button {
  width: 4rem;
  height: 4rem;
  max-width: 4rem;
  max-height: 4rem;
  background-color: #0000;
}

.button:hover, .clickable:hover {
  background-color: #efefef;
  transition: 0.3s;
}

.email-wrapper {
  display: flex;
  width: 100%;
}

.input-list {
  flex: 1;
  position: relative;
  width: 100%;
}

.input {
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  width: calc(100% - 1rem);
}

.input:focus {
  outline: none;
}

.input-label {
  position: absolute;
  top: 1px;
  left: 1rem;
  height: 0.5em;
  font-size: 10px;
}

.input.show {
  border: 1.5px solid red;
  padding-top: 10px;
}

.error {
  color: rgb(255, 0, 0);
}

.error.hide {
  display: None
}