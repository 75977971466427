@use '../../utils/base';

.input {
  width: 100%;
  padding: 0.375rem 0.75rem;

  color: #495057;

  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  
  &:disabled {
    color: base.$disabled;
  }
}