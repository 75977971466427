.header {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  height: 24px;
}

.label {
  padding-left: 8px;
  width: 120px;
  font-weight: bold;
}

.name-box {
  display: flex;
  flex: 1;
  gap: 4px;
  align-items: center;

  input {
    flex: 1;
  }
  i {
    cursor: pointer;
  }
}

// creates clickable area same as regular NodeBoxButton with contents centered
.edit-name-button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}