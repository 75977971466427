@use "../../utils/utils";

.show-on-mobile {
    display: none;
}

.hide-on-mobile {
    display: flex;
}

@include utils.phone-and-tablet {
    .hide-on-mobile {
        display: none !important;
    }
}
@include utils.phone-and-tablet {
    .show-on-mobile {
        display: flex;
    }
}
