.summary {
  padding: 5px 4px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  flex-direction: column;
}

.separator {
  border-top: 1px solid lightgray;
}