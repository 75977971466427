/*4th step date range title*/

h4.inst-title.insttime-title {
    margin-top: 0px;
    font-size: initial;
}


button#load-data-table {
    padding: 2px;
    float: right;
}


/*back-next section at the steps*/
.next-back-btns-wrap {
    margin: 10px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.61);
}

.step-wrap-cst {
    cursor: default;
}


.not-set {
    color: #c55;
    font-style: italic;
}


.dashboard-chart {
    width: auto;
    margin-bottom: 20px;
    display: block;
}

/*scroll bar*/

::-webkit-scrollbar {
    background: #f5f5f5;
    height: 8px;
    width: 13px;
}

::-webkit-scrollbar-thumb {
    background: #627381;
    border-radius: 4px;
}


/*dashboard decoration*/


tr.show-sensors:hover,
.table-striped > tbody {
    cursor: pointer;

}

tbody > tr.show-sensors.active-device > td > a {
    color: white;
}

tbody > tr.show-sensors.active-device {
    background-color: #333;
    cursor: pointer;
    color: white;
}

tbody > tr.show-sensors.active-device:hover {
    background-color: #333;
}

tr.show-sensors.not-clickable {
    cursor: auto;
}

tr.show-sensors {
    text-align: center;

}

.table > tbody > tr > td {
    padding: 2px 8px 2px 8px;
}

tbody > tr.show-sensors.no-sensors,
tbody > tr.show-sensors.no-sensors:hover {
    cursor: default;
    tab-index: -1;
    color: grey;
    background-color: #DDD;

}

tbody > tr.show-sensors.no-sensors,
tbody > tr.show-sensors.no-sensors:hover {
    cursor: default;
    color: grey !important;
    background-color: #DDD !important;

}


.modal-header {
    background: #333;
    color: white;
}


.nav-pills > li.active > a,
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    background: rgba(255, 255, 255, 0);
    border-top: 4px solid #f0ad4e;
    padding-top: 5px;
    border-radius: 0;
}

.nav-pills > li.active > a:hover,
.nav-pills > li > a:hover {
    background: rgba(255, 255, 255, 0);
}


h1 {
    color: #333;
}

/*Secondary navigation*/

#cst-secondary-nav {
    background: #f5f5f5;
}

/* Pagination */
.pagination {
    margin-bottom: 0px;
    margin-top: 0px;
}


.dashboard-index {
    background-color: #ededed;
}

.dashboard-index .step-icon {
    background-color: #ededed;
    font-size: 28px;
    position: relative;
    top: 5px;
}

.dashboard-index .wrapper {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.dashboard-index .content-wrapper {
    display: table-row;
    width: auto;
}

.dashboard-index .content-wrapper .col-table {
    display: table-cell;
    vertical-align: top;
}

.dashboard-index .content-wrapper .col-table {
    padding: 15px 20px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
}

.dashboard-index .btn-wizard {
    border-radius: 0;
}

@media (max-width: 992px) {


    .dashboard-index .content-wrapper {
        display: block;
    }

    .dashboard-index .content-wrapper .col-table {
        display: block;
    }

}


thead > tr:first-child  {
    background: #333;
    color: white;
}

thead > tr:first-child a {
    color: white;
}

thead > tr:nth-child(2)  {
    background: #333;
    color: white;
}


.passive-st {
    background-color: #f0ad4e;
    color: #fff;
}

.active-st{
    background-color: #5cb85c;
    color:#fff;
}


.inst-title {
    color:#337ab7;
}


#load-data-table {
    float:right;
}


.modal-body p.alert.alert-success {
    background-color: #dff0d8;
    text-align: center;
}


.steps-icons {
    color: #f0ad4e;
    margin: 5px 10px 5px 8px;
}


.not-set {
    color: #c55;
    font-style: italic;
}

.active {
    display: block;
}

.new-sensor-name.error {
    border: 1px solid red;
}

/* Global style for links */
a {
  color: #6da92d;
}
a:hover {
  color: #87d137;
}