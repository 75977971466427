@use "../../utils/utils";

.pagination {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  button {
    padding: 0.5rem 1rem;
    background-color: white;

    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: #ddd;
    overflow: hidden;

    &:disabled{
      color: lightgray;
    }
    &[data-current=true] {
      background-color: #454545;
      color: white;
      border-color: #454545;
    }
  }

  :first-child {
    border-width: 1px;
    border-radius: 4px 0 0 4px;
  }
  :last-child {
    border-radius: 0 4px 4px 0;
  }

  @include utils.phone-and-tablet {
    display: flex;
    flex-direction: column;

    button {
      border-width: 0 1px 1px 1px;
    }

    :first-child {
      border-width: 1px;
      border-radius: 4px 4px 0 0;
    }
    :last-child {
      border-radius: 0 0 4px 4px;
    }
  }
}
