@use "../../utils/base";



.info-box {
  display: flex;
  flex-direction: row;

  .descriptor-side {
    width: 50%;

    font-weight: bold;
  }
  .data-side {
    text-align: end;
  }
}

.text-box {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;

  .icon {
    width: 2rem;
    height: 2rem;
  }

  .content {
    margin: 1rem;
  }
}
