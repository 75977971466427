@use "../../utils/utils";
@use "../../utils/base";

$max-height: 5rem;
$phone-horizontal-height: 340px;

@mixin vertical-center-items {
  display: flex;
  align-items: center;

  text-decoration: none;
}

.stack-order-ham {
  z-index: 99999;
}

.nav-link-bottom {
  a {
    color: #333333; // todo: define globally
    &:hover {
      color: black;
    }
  }
  .active {
    padding-bottom: 6px;
    border-bottom: 4px solid #c7db01;
  }
}

.nav-links {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  width: 50%;
  height: 100%;
  align-items: center;
  margin-left: auto;

  li {
    display: flex;
    height: 100%;

    .nav-btn-wrapper {
      align-items: baseline;
      min-width: max-content;
    }

    .margin-reset {
      margin-left: 0 !important;
    }
  }
}

@include utils.phone-and-tablet {
  .line {
    width: 30px;
    height: 3px;
    background: white;
    margin: 5px;
  }
  nav {
    position: relative;
  }

  .hamburger {
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    outline: none;
  }

  .nav-links {
    $narrow-circle: circle(10px at 90% -10%);
    position: fixed;
    background: utils.$navbar-color;
    height: 100%;
    width: 100%;
    flex-direction: column;
    clip-path: $narrow-circle;
    -webkit-clip-path: $narrow-circle;
    transition: all 0.7s ease-out;
    pointer-events: none;
    z-index: 1;


    li {
      opacity: 0;

      .nav-btn-wrapper {
        width: 100%;
        align-items: center;
        min-width: auto;
      }
    }
  }
  .nav-links.open {
    $expanded-circle:  circle(300vh at 90% 00%);
    clip-path: $expanded-circle;
    -webkit-clip-path: $expanded-circle;
    pointer-events: all;
    justify-content: space-around;
  }
  .nav-links,
  .logout {
    font-size: 1.5rem;

    @media (max-height: #{$phone-horizontal-height}) {
      font-size: 1rem;
    }
  }
  li.fade {
    opacity: 1;
  }

  .selected {
    width: 100vw;
    justify-content: center;
  }

  li {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    div {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  li.fade.sub-par-menu {
    display: flex;
    background-color: utils.$navbar-color-subbar;
    width: 100vw;
    justify-content: center;
    font-size: 1rem;

    a.active {
      width: 100vw;
      justify-content: center;
      background-color: utils.$navbar-color-subbar-selected;
    }
  }
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: utils.$navbar-color;

  height: $max-height;

  padding-right: 2.5rem;
  .left {
    padding-left: 2.5rem;
    @include vertical-center-items;
    a {
      padding-left: 1rem;
      img {
        height: $max-height - 1rem;
      }
    }
  }

  a,
  .dropdown,
  .logout {
    @include vertical-center-items;

    padding-left: 1rem;
    padding-right: 1rem;
    height: 100%;
    text-decoration: none;
    color: utils.$non-selected-item-text;
    &:hover {
      color: utils.$non-selected-item-hover-text;
    }

    @media (max-height: #{$phone-horizontal-height}) {
      font-size: 0.8rem;
    }
  }

  .hidden,
  .carret {
    color: utils.$non-selected-item-text;
    height: 100%;
    outline: none;
    i {
      margin-left: 1rem;
    }
  }
  .hidden {
    visibility: hidden;
  }

  .selected {
    color: utils.$navbar-item-selected-item-text-color;
    a,
    div, .carret {
      color: utils.$navbar-item-selected-item-text-color;
    }
    background-color: utils.$navbar-item-selected-bg-color;
    height: 100%;
    text-decoration: none;
  }

  .sub-par-menu {
    display: none;
  }
}
