@use '../../utils/base';

.button {
  background-color: base.$base-blue;
  color: white;
  min-width: 6rem;
  height: 3rem;
  border-radius: 4px;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover {
    background-color: #2a6599;
  }
  &:active {
    background-color: #245885;
  }
  &:disabled {
    background-color: lightgray;
  }

  &[data-style=GRAY] {
    background-color: gray;

    &:hover {
      background-color: darkgray;
    }
    &:active {
      background-color: lightgray;
    }
    &:disabled {
      background-color: lightgray;
    }
  }

  &[data-style=RED] {
    background-color: #dc3545;

    &:hover {
      background-color: #c42f3e;
    }
    &:active {
      background-color: #ad2f3c;
    }
    &:disabled {
      background-color: lightgray;
    }
  }

  &[data-style=GREEN] {
    background-color: #28a745;

    &:hover {
      background-color: #25923f;
    }
    &:active {
      background-color: #1e8135;
    }
    &:disabled {
      background-color: lightgray;
    }
  }
}