@use "../utils/utils";

.login-wrapper {
    margin-top: 5rem;
    margin-left: 5rem;
    width: 30rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    .login-content {
        display: flex;
        flex-direction: column;
    }

    @include utils.phone {
        margin-left: 2rem;
        width: 20rem;
    }
    @include utils.narrow-phone-width-exceeded {
        margin-left: 1rem;
        width: 16rem;
    }
}
