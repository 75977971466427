@use '../../../../utils/base';

.button {
  background-color: base.$base-blue;
  padding: 1rem;
  color: white;
}

.button:hover {
  background-color: #333;
  padding: 1rem;
}

.button:active {
  background-color: #000;
  padding: 1rem;
}