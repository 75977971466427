.button {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: #f1f1f1;
  color: #454545;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s;

  &:hover {
    background-color: lightgray;
    color: black;
  }
}