@use "../utils/base";
@use "../utils/utils";


.sensor-checkbox-checker {

    .collapsed-true {
        background: utils.$btn-select-blue-hover;
        border-radius: 0.3rem 0.3rem 0rem 0rem;
    }

    .collapsed-false {
      border-radius: 0.3rem;
    }

    .title {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }

    .collapsable-content {
      background: base.$active-blue;
      border-radius: 0rem 0rem 0.3rem 0.3rem;
      overflow: hidden;
    }

    .checkbox-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      color: white;
      font-weight: bold;
      font-size: 100%;

      padding: 1rem;
    }

    .checkbox-style {
      margin-right: 1rem;
      margin-left: 0.3rem;
    }
}