@use "../utils/base";
@use "../utils/utils";


.expandable-button-container {
  display: flex;
  flex-direction: column;

  &[data-expanded=true] {
    &>button {
      border-radius: 4px 4px 0 0;
    }
  }

  .title {
    margin: auto;
    padding-left: 21px;
  }
  
  .content-wrapper {
    background-color: #555;
    padding: 0.5rem;
    border-radius: 0 0 4px 4px;

    button {
      width: 100%;
      height: 36px;
      margin-top: 0.5rem;
      border-radius: 4px;

      font-size: 90%;
      color: #333;

      border-radius: 4;
    }

    p {
      color: white;
    }
  }
}