.sensor-row {
  background-color: #f1f1f1;
  border-radius: 4px;

  &[data-enabled=true] {
    background-color: #E5F0D8;
  }
}

.row {
  display: flex;
  gap: 4px;
  align-items: center;
  height: 32px;
  padding: 4px;
}

.row-selector {
  display: flex;
  padding: 0px 4px;
  gap: 8px;
  width: 80px;
}

.right {
  flex: 1;
  justify-self: end;
  display: flex;
  justify-content: right;
  gap: 4px;
}

.label {
  padding: 0 4px;
}