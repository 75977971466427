@use '../../utils/base';

.header {
  background-color: base.$base-blue;
  display: flex;
  height: 4rem;
  align-items: center;
}

.title {
  flex: 1;
  color: white;
  margin-left: 1rem;
  font-size: 1.6rem;
}

.close-button {
  width: 4rem;
  height: 4rem;
  background-color: base.$base-blue;

  &:hover {
    background-color: #2a6599;
  }
  &:active {
    background-color: #245885;
  }
}