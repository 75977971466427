.slider-container {
  width: 100%;
  padding-top: 1rem;
  text-align: center;
}

.background {
  height: 16px;
  border: 2px solid #eeeeee;
  border-radius: 8px;
  position: absolute;
  left: 0px;
  right: 0px;
}

.slider-label {
  display: flex;
  justify-content: space-between;
}

.range {
  position: absolute;
  background-color: #eeeeee;
  height: 16px;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.slider {
  appearance: none;
  height: 14px;
  background-color: transparent;
  position: relative;
  outline: none;
  z-index: 1;

  &:disabled {
    cursor: not-allowed;

    &::-webkit-slider-thumb {
      background-color: #cccccc;
      cursor: not-allowed;
    }

    &::-moz-range-thumb {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #BCCF00;
  border: 1px solid white;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #BCCF00;
  border: 1px solid white;
  cursor: pointer;
}
