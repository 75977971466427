.component {
  display: flex;
  width: 100%;
  align-items: center;
}

.socket-selector-box {
  margin-bottom: 1rem;
}

.weekday-selector-box {
  flex: 1;
  padding-top: 1rem;

}

.small-weekday-box {
  padding: 1rem 4rem 0rem 4rem;
}

.time-range-selector-box {
  margin-top: 1rem;
}

.time-range-selection {
  display: flex;
  align-items: center;

  label {
    margin: 0px;
    width: 4rem;
    text-align: center;
  }

}

.delete {
  height: 5.5rem;
  width: 4.5rem;
  color: white;
  background: none;
  margin-left: 1rem;
  border-left: 1px solid lightgray;

  img {
    width: 3rem;
    height: 3rem;
  }
}

.selector-box {
  flex: 1;
}
