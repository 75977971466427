.container {
  display: flex;

  justify-content: center;
  width: 100%;
}

.weekday {
  margin: auto;
  width: 3.5rem;
  height: 3.5rem;

  border-radius: 50%;
  border: 1px solid #cfcfcf;

  label {
    height: 100%;
    font-size: 2rem;
    
    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
  }

  &[data-selected=true] {
    background: #BCCF00;
    border: none;
    
    label {
      color: white;
    }

    &[data-disabled=true] {
      background-color: lightgray;
    }
  }

  &[data-red=true] {
    border-color: red;
  }

  &[data-disabled=true] {
    border-color: lightgray;
    color: lightgray;
  }

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &[data-small=true] {
    width: 1.5rem;
    height: 1.5rem;
    
    label {
      font-size: 0.8rem;
      font-weight: bold;
    }
  }
}