@use '../../utils/base';

.wrapper{
  position: fixed;
  background-color: #0004;
  width: 100%;
  height: 100%;
  z-index: 100000; // this will raise it above title bar
  top: 0;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  animation-duration: 0.3s;
  overflow-y: auto;
  
  &[data-show=true]  {
    animation-name: wrapper-show;
  }
  
  &[data-show=false] {
    animation-name: wrapper-hide;
    opacity: 0;
  }
}

.modal {
  min-width: 10rem;
  background-color: white;
  margin: 1rem;

  animation-duration: 0.3s;

  &[data-show=true]  {
    animation-name: modal-show;
  }
  
  &[data-show=false] {
    animation-name: modal-hide;
    opacity: 0;
  }
}

@keyframes wrapper-show {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes wrapper-hide {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes modal-show {
  0% {
    translate: 0px -10px;
    opacity: 0;
  }
  100% {
    translate: 0px 0px;
    opacity: 1;
  }
}
@keyframes modal-hide {
  0% {
    translate: 0px 0px;
    opacity: 1;
  }
  100% {
    translate: 0px 10px;
    opacity: 0;
  }
}