.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
  //TODO: investigate better implementation options
  margin-top: 8px; // for some reason element was displaced and this padding used to center it where it is used
  margin-right: 1rem;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 28px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #BCCF02;
}

input:focus + .slider {
  box-shadow: 0 0 1px #BCCF02;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

input:disabled + .slider {
  cursor: not-allowed;
}