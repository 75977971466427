.checkbox {
  width: 18px;
  height: 18px;
  background-color: white;
  display: inline-block;
  vertical-align: top;

  border: 1px solid black;
  border-radius: 2px;

  svg {
    vertical-align: top;
  }

  &[data-highlightRed=true] {
    border-color: red;
  }

  &[data-checked=true] {
    background-color: #BCCF00;
    border: 1px solid #BCCF00;  
  }
}