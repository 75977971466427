@use '../../../utils/utils';

.row {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 36rem) { 
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
}

.label {
  flex: 1;
}

.dropdown {
  min-width: 16rem;

  @media only screen and (max-width: 36rem) {
    width: 100%;
  }
}

.input {
  width: 16rem;

  @media only screen and (max-width: 36rem) {
    width: 100%;
  }
}

.exclamation {
  color: red;
  margin-left: 1rem;
  font-size: 1.2rem;
}

