.frame {
  border: 1.5px solid gray;
  background-color: white;
  border-radius: 4px;
  width: 24px;
  height: 100%;
  padding: 2px;
}

.moisture-indicator {
  border-radius: 2px;
  width: 100%;
  height: 100%;
}

.dry {
  background-color: forestgreen;
}

.moist {
  background-color: gold;
}

.very-moist {
  background-color: red;
}

.na {
  background-color: black;
}