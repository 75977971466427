.button {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 24px;
  min-width: 24px;
  padding: 0 4px;
  border-radius: 4px;

  color: white;
  background-color: #454545;
  
  img, i {
    min-width: 16px;
  }

  &[data-on=true] {
    background-color: #28a745;
  }

  &:disabled {
    background-color: lightgray;
    cursor: not-allowed;
  }

  &[data-action-bar=true] {
    color: #454545;
    background-color: white;

    &[data-on=true] {
      i { color: #28a745; }
    }

    &:disabled {
      color: lightgray;
      cursor: not-allowed;
    }
  }

  &[data-on-off=true] {
    width: 56px;
  }
}