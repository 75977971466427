.tooltip {
  background-color: white;
  color: dimgray;

  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0px 1px 3px dimgray;
  // border: 1px solid gray;
}

.triangle {
  // Center the triangle
  margin: auto;
  // Used CSS triangle generator which generates triangles using borders
  // While the generator also offers to generate downwards pointing triangle I used bottom right pointing one
  // This way shadow can be created right and bottom side which then will be rotated along with the triangle
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1rem 0 0 1rem;
  border-color: transparent transparent transparent white;
  box-shadow: -2px 2px 1px gray;
  // Top left corner will be used for rotation to point triangle downwards
  // Slight offset added to eliminate slight gap between popup and triangle by overlapping the triangle
  // Slight offset also added to help center the triangle as bounding box will stay the same after rotation
  transform-origin: -2px 2px;
  transform: rotate(-45deg);
}

.component-wrapper {
  position: relative;
  // Using padding for spacing which will increase the hover area making it easier to tap on mobile
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-flex;
  align-items: center;

  .tooltip-wrapper {
    position: absolute;
    // tooltip will not be displayed by default
    display: none;

    // position it above parent
    bottom: 100%;
    // move it forward 50% of the parent's width
    left: 50%;
    // move it backward 50% of its own width
    transform: translate(-50%);
  }

  // hover style used to make tooltip visible
  &:hover {
    color: #454545;

    .tooltip-wrapper {
      display: block;
    }
  }
}

.battery {
  font-size: 1.25rem;
}