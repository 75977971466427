@use "../../utils/utils";

.footer-real {
    background: utils.$navbar-color;
    color: utils.$non-selected-item-text;
    box-shadow: 0 2px 10px 0 rgba(16, 16, 16, 0.5);

    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
