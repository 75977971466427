@use '../../../utils/base';

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.handle {
  position: absolute;
  width: 100%;
  appearance: none;
  background-color: transparent;
  pointer-events: none;

  &[data-hide=true] {
    opacity: 0%;
  }
}

.handle-a {
  z-index: 10;
}

.handle-b {
  z-index: 11;
}

.slider {
  position: relative;
  width: 100%;
  background-color: #eeeeee;
  height: 10px;
  border-radius: 5px;
  margin: 9px;
}

.selection {
  position: absolute;
  height: 10px;
  border-radius: 5px;
  background-color: #DAEC2A;
}

.handle::-moz-range-thumb {
  background-color: #BCCF00;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  width: 18px;
  pointer-events: all;
}

.handle[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #BCCF00;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  width: 18px;
  pointer-events: all;
}