.grid {
  display: grid;
  min-width: fit-content;
  overflow-x: clip;
}

.cell {
  display: flex;
  height: 100%;
  gap: 4px;
  min-height: 32px;
  padding: 2px 4px;
  align-items: center;
  color: black;

  &[data-even-row=true] {
    background-color: #f8f8f8;
  }
  &[data-gray-row=true] {
    color: gray;
  }
  &[data-hover-row=true] {
    background-color: #eee;
  }
  &[data-selected=true] {
    background-color: #e0e0e0;
  }
}

.header {
  height: 4rem;
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;

  button {
    background-color: white;
    width: 2rem;
    height: 2rem;
    border-radius: 2px;

    &:hover {
      border: 1px solid black;
    }
  }
}