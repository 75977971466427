.container {
  display: flex;

  @media only screen and (max-width: 48rem) {
    flex-direction: column;
  }
}

.left {
  display: flex;
  gap: 1rem;
  flex: 1;

  h4 {
    margin: 0px;
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.right {
  display: flex;
  justify-content: center;
}

.separator {
  background-color: #b2b2b2;
}