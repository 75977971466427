@use '../../../../utils/base';

.component-wrapper{
  background-color: base.$base-blue;
  display: flex;
  height: 4rem;
}

.title {
  color: white;
  margin: auto 0 auto 1rem;
  font-size: 1.5rem;
}

.content {
  flex: 1;
  color: white;
  display: flex;
  justify-content: flex-end;
}