@use '../../utils/base';

.calendar-style {
  height: 30rem;
}


.title {
  margin-bottom: 0.5rem;
  color: white;
  margin: 0.25rem;
}

.warning {
  font-size: 0.8rem;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 44% 13% 1% 13%;
  height: 100%;
}


.column-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.column {
    display: flex;
    flex-direction: column;
    margin: 0.3rem;
    width: 50%;
    position: relative;
}

.disabled-column {
  opacity: 0.5;
  pointer-events: none;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.test {
  background-color: #0a0a;
  width: 100%;
  height: 100%;
}

.padlock {
  background: url("../../images/padlock.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 7.2rem;
  width: 7.2rem;
}

.padlock-wrapper {
  position: absolute;
  padding-top: 2.4rem; // title of column
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
}

.type-select {
  padding: 0.375rem 0.75rem;

  font-weight: 400;
  color: #495057;
  width: 100%;

  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.type-select:disabled {
  color: base.$disabled;
}

.min-max-checkbox {
  padding: 0.375rem 0.75rem;
  
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.descriptor {
  margin-left: 0.3rem;
  width: 5rem;
  color: white;
}
