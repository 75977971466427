@use "../../utils/utils";

$widget-border-color: #727272;
$total-height: 100%;
// $total-height: 1.7rem;

.color-wrapper {
  display: flex;
  height: 100%;
  min-height: 1.7rem;
  @include utils.narrow-phone-width-exceeded {
    flex-direction: row;
  }

  .data-color-container {
    display: flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden; // clip contents for rounded corners
    min-width: 7.5rem;

    @include utils.narrow-phone-width-exceeded {
      flex-direction: row;
    }

    .latest-data-style {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 80%;
      color: black;

      background-color: white;

      width: 100%;
      height: $total-height;
    }

    .color-picker-button-style {
      margin-left: auto;
      cursor: "pointer";

      min-width: 24px;
      height: $total-height;
    }
  }

  .popover {
    position: absolute;
    z-index: 2;
  }
}
