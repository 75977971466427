/* Colours to be used */

/* Everything related to the Internationalization view */
$warning-red: red;

/* Everything related to the Installation view */
$btn-select-blue-hover: #286090;
$sel-checkbox-background: #6298c7;
$btn-next-disabled: gray;
$chart-button-font: white;

/* Everything related to the navbar */
$navbar-color: #333333;
$navbar-color-subbar: #616161;
$navbar-color-subbar-selected: #505050;
$navbar-item-selected-bg-color: #080808;
$non-selected-item-text: #9d9d99;
$non-selected-item-hover-text: white;
$navbar-item-selected-item-text-color: white;

/* Everything related to alarm page list */
$btn-bg-gray: #ABABAB;
$btn-select-blue: #4787be;
$btn-select-red: #DA4545;

/* Everything related to alarm logs page */
$option-select: #DEBA3A;

/* Everything related to PWR table */
$tabs-selected: #6298c7;
$tabs-txt-active: white;
$tabs-not-selected: #EDEDED;
$tabs-txt-not-active: black;

/*  */
$very-narrow-phone-width: 370px;  // e.g. iPhone 5/SE
$node-box-width: 500px;
$phone-width: 768px;
$tablet-width: 1024px;

@mixin tablet {
  @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin node-box-width-exceeded {
  @media (max-width: #{$node-box-width}) {
    @content;
  }
}
@mixin narrow-phone-width-exceeded {
  @media (max-width: #{$very-narrow-phone-width}) {
    @content;
  }
}

@mixin phone-and-tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}


@mixin desktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}
