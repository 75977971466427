@use "../utils/utils";
@use "../utils/base";

.chart-view {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  @include utils.phone-and-tablet {
    display: flex;
    flex-direction: column;
  }

  gap: 1rem;

  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include utils.desktop {
      min-width: 23rem;
      max-width: 23rem;
    }

    .sidebar-block {
      padding: 1rem;
      padding-bottom: 1rem;
      display: flex;
      gap: 1rem;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: base.$base-blue;
      color: white;
      border-radius: 0.3rem;
    }
  }
}

.amchart {
  width: 100%;
  display: flex;
  height: 35rem;
  justify-content: center;
}

.node-info-container {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding-top: 1rem;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  @include utils.phone-and-tablet {
    flex-direction: column;
  }
}

.cogs {
  position: relative;

  button {
    background-color: #ffffff00;
    border: None;
    outline: None;

    i {
      background-color: #7b7b7b;
      padding: 0.3rem;
      border-radius: 0.3rem;
      color: white;
      font-size: 1rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.chart-button {
  display: flex;
  justify-content: center;
  width: 100%;

  padding: 0.5rem;
  border-radius: 0.3rem;
  border-width: 0rem;

  outline: none;

  transition-duration: 0.3s;

  font-size: 100%;
  color: utils.$chart-button-font;

  background: base.$base-blue;
}

.chart-button:hover {
  background: utils.$btn-select-blue-hover;
}
