@use '../../utils/base';

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color-scheme: light;
  accent-color: base.$base-blue;
  margin: 0;

  input[type="radio"] {
    appearance: none;
    min-width: 1.5rem;
    min-height: 1.5rem;
    
    border: 1px solid lightgray;
    border-radius: 50%;
    
    transition-property: background-color, border-color, outline, outline-offset;
    transition-timing-function: ease-in;
    transition-duration: 100ms;

    &:checked {
      background-color: #BCCF00;
      box-shadow: 0 0 0 2px white inset;
      border-color: #BCCF00;
    }
    
    &:not(:checked):hover {
      border-color: #BCCF00;
    }

    &:focus-visible {
      outline: 1px dashed gray;
    } 
  }
}
