.container {
  display: flex;
  padding: 0px;
  margin: 0px;
}

.socket {
  width: 25%;
  display: flex;
  align-items: center;
  
  label {
    vertical-align: top;
    padding-left: 0.5rem;
    margin: 0px;
  }
}