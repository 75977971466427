.green-completed {
    color: #9abf48;
}

.index-small-btn {
    padding: 0rem .5rem;
}

html * {
    font-family:
    Arial,
    sans-serif;
}

.pointer-on-hover {
    cursor: pointer;
}

.color-changer-style {
    min-height: 100%;
    min-width: 100%;
    color: white;
    border: 0;
}

.color-picker-disabler {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.instructionTitle {
    padding: 15px 20px;
    border-bottom: 1px solid rgba(208, 219, 220, 0.68);
    color: #9c989d;
}

.instructionBody {
    padding: 15px 20px;
    height: 70%;
    overflow: auto;
    bottom: 0;
}


/* Spinny thingy at Active Installation List View */
.spinny-thingy-container {
    height: 70vh;
}

.water-alarm {
    height: 10px;
    background: repeating-linear-gradient(45deg, black, black 35px, #e2f04e 35px, #e2f04e 70px);
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}


a:link {
    text-decoration: none;
}

.btn-account {
    color: #9D9D99;
}

.btn-account:hover {
    color: white;
}

.spinny-thingy-margin {
    margin-top: 20vh;
}

.success-box-color {
    background-color: #C9EBD1;
}

.error-box-color {
    background-color: #E3C2C5;
}

.active-device {
    background-color: #337ab7 !important;
}

.light-grey-text {
    color: #9D9D9D;
}

.triangle-const-width {
    display: flex;
    justify-content: center;
    width: 1rem;
}

.selector-z-index {
    position: relative;
    z-index: 50;
}

.selector-z-index-tier-2 {
    position: relative;
    z-index: 40;
}

/* This is to keep the footer down */
html,
body {
    width: 100%;
    height: 100vh;
    font-size: 14px;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

#content,
main {
    display: flex !important;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
}
#content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
}
