@use "../../utils/base";
@use "../../utils/utils";

.node {
  width: 380px;

  border: 1px solid black;
  border-radius: 4px;

  &[data-active=true] {
    border-color: #5CB85C;
  }
  &[data-active=false] {
    border-color: #F0AD4E;
  }
  &[data-error=true] {
    border-color: #C12B2B;
  }
}

.status-bar {
  display: flex;
  align-items: center;
  padding: 4px;
  height: 32px;

  &[data-active=true] {
    background-color: #5CB85C;
  }
  &[data-active=false] {
    background-color: #F0AD4E;
  }
  &[data-error=true] {
    background-color: #C12B2B;
  }
}

.controls-box {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 4px;
}

.status-text {
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  padding: 0px 8px;
}

.contents {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
}

.no-data {
  background-color: #f1f1f1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
}

.separator {
  width: 1px;
  height: 24px;
  background-color: white;
}