.button {
  color: white;
  width: 6rem;
  height: 3rem;
  border-radius: 4px;

  background-color: gray;

  &:hover {
    background-color: darkgray;
  }
  &:active {
    background-color: lightgray;
  }
}