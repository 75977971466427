@use '../../../utils/base';
@use '../../../utils/utils';

.form {
  width: 45rem;
  
  @media only screen and (max-width: utils.$phone-width) {
    max-width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  padding-top: 0.5rem;
}

.slider-info {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  label {
    vertical-align: middle;
    margin-left: 0.5em;
  }
}
