.prose-block {
  max-width: 1000px;
  padding: 2rem;
  margin: auto;
  color: #333;

  ul {
    margin-left: 1rem;
  }

  h1, h2 {
    color: black;
    margin-top: 2rem;
  }
}