.level-container {
  padding: 1px;
  display: flex;
  width: 100%;
  height: 100%;
}

.level {
  flex: 1;
  margin: 1px;
  border-radius: 3px;
}

.no-data {
  width: 100%;
  text-align: center;
}
