.label {
  padding: 4px;
}

.end {
  color: black;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.button {
  color: white;
  background-color: #7b7b7b;
  width: 2rem;
  border-radius: 3px;
}