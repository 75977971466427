.patterns-list {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  row-gap: 3rem;
  align-items: start;
  
  @media only screen and (max-width: 110rem) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 64rem) {
    grid-template-columns: 1fr;
  }
}